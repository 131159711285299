import * as Sentry from '@sentry/remix';

import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import env from './config/env';
import { AutoUpdateStore } from './lib/auto-update';
import { CacheStore } from './lib/cache-store';
import { ObjectPool } from './models/object-pool';

// Fix "ResizeObserver loop completed with undelivered notifications" error
// by monkeypatching ResizeObserver to debounce callbacks
// https://github.com/vuejs/vue-cli/issues/7431
//
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

if (import.meta.env.MODE === 'production') {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY || '', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
        color: false,
        date: false,
        'datetime-local': false,
        email: false,
        month: false,
        number: false,
        range: false,
        search: false,
        tel: false,
        text: false,
        time: false,
        url: false,
        week: false,
        textarea: false,
        select: false,
      },
    },
  });
}

let capacitorAppInfo: { build: string; version: string } | null = null;
try {
  if (Capacitor.isNativePlatform()) {
    CapacitorApp.getInfo().then((info) => (capacitorAppInfo = info));
  }
} catch {
  capacitorAppInfo = null;
}

Sentry.init({
  dsn:
    import.meta.env.MODE === 'production'
      ? 'https://fa7cdb92e557a639f1e44390f13092c0@o4505745106599936.ingest.us.sentry.io/4508179044040704'
      : '',
  tracesSampleRate: 1,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
  beforeSend(event) {
    const postHogSessionUrl = posthog.get_session_replay_url({
      withTimestamp: true,
    });

    if (postHogSessionUrl) {
      event.tags = { ...event.tags, posthog_session_url: postHogSessionUrl };
    }

    if (capacitorAppInfo) {
      event.tags = {
        ...event.tags,
        nativeAppBuild: capacitorAppInfo.build,
        nativeAppVersion: capacitorAppInfo.version,
      };
    }

    return processUncaughtError(event);
  },
});

function processUncaughtError(event: Sentry.ErrorEvent) {
  console.log('Processing uncaught error: ', event);

  const values = event.exception?.values;
  if (!values) return event;

  if (
    values.some((value) =>
      value.value?.includes('Connection to Indexed Database server lost')
    )
  ) {
    console.warn(
      'Detected IndexedDB connection loss. Triggering page refresh.'
    );
    window.location.reload();
    return null;
  }

  if (values.some((value) => value.type === 'IDBNotFoundError')) {
    console.warn('Detected IndexedDB not found. Triggering page refresh.');
    window.location.reload();
    return null;
  }

  return event;
}

function clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        'html > *:not(body, head)',
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(', ')
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector('html')!,
      allowedAttributes: ['lang', 'dir', 'class'],
    },
    head: {
      $elm: document.querySelector('head')!,
      allowedAttributes: [''],
    },
    body: {
      $elm: document.querySelector('body')!,
      allowedAttributes: ['class', 'style'],
    },
  };

  Object.entries($targets).forEach(([targetName, target]) => {
    target.$elm.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm.removeAttribute(attr);
      }
    });
  });
}

function hydrate() {
  if (Capacitor.isNativePlatform()) {
    LiveUpdate.ready();
  }

  const savedLocation = localStorage.getItem('savedLocation');
  if (savedLocation) {
    localStorage.removeItem('savedLocation');
    console.log('restoring saved location', savedLocation);
    window.location.href = savedLocation;
    return;
  }

  const objectPool = ObjectPool.initializeSingleton();
  CacheStore.initializeSingleton(objectPool);
  AutoUpdateStore.initializeSingleton(
    env.VITE_AUTO_UPDATE_URL,
    env.VITE_AUTO_UPDATE_PASSWORD,
    env.VITE_REVISION
  );

  // https://gist.github.com/OnurGvnc/31f03f0d5237b78224aa083493fda645
  // Remove after React 19 upgrade
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <PostHogProvider client={posthog}>
          <RemixBrowser />
        </PostHogProvider>
      </StrictMode>
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
